import React, { useRef, useEffect } from 'react';
import './App.css';

const VideoComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handlePlay = () => {
      if (document.fullscreenElement) {
        document.exitFullscreen().then(() => {
          videoRef.current.requestFullscreen();
        });
      } else {
        videoRef.current.requestFullscreen();
      }
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener('play', handlePlay);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
    };
  }, []);

  return (
    <video ref={videoRef} className="placeholderVideo" poster="./video-thumbnail-3240x1250.webp" controls>
        <source src="./video_compressed.mp4" type="video/mp4" />
        <source src="./video_compressed.webm" type="video/webm" />
        Your browser does not support the video tag.
    </video>
  );
};

export default VideoComponent;